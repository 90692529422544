import {I18n as T18n} from 'i18n-js'
import translations from '../locales.json'

const I18n = new T18n()

I18n.store(translations)
I18n.enableFallback = true
window.I18n = I18n

export default I18n
